.wrap {
  position: fixed;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  background-color: rgb(0, 0, 0, 0.3);
  z-index: 999;
  opacity: 0;
  transition: opacity 0.3s;
  pointer-events: none;
}

.wrap.open {
  opacity: 1;
  pointer-events: all;
}

.wrap .menu {
  height: 100%;
  width: 80%;
  max-width: 20rem;
  background-color: rgb(255, 255, 255, 0.8);
  box-shadow: var(--box-shadow);
  transform: translateX(-100%);
  transition: transform 0.3s;
  overflow: hidden;
  position: relative;
  backdrop-filter: blur(0.4rem);
}

.wrap.open .menu {
  transform: translateX(0);
}

.menu {
 padding: 1.5rem; 
}

.menu a {
  display: block;
  margin-top: 1rem;
  font-size: 1.6rem;
}

.closeIcon {
  position: absolute;
  bottom: 2rem;
  left: 2rem;
  font-size: 1.6rem;
}