.wrap {
  width: 100%;
  background-position: top center;
  background-size: contain;
  background-repeat: no-repeat;
  background-image: url(../../assets/home-bg-video-poster.jpg);
  padding: 2rem 0;
}

.modal {
  width: 900%;
  max-width: 60rem;
}

.resources {
  width: 100%;
  display: flex;
  justify-content: space-evenly;
  align-items: flex-start;
  flex-wrap: wrap;
  padding: 0 5%;
}

.resources .item {
  width: 24%;
  margin: 1rem 2%;
  text-align: center;
  cursor: pointer;
  min-width: 12rem;
  display: block;
}

.resources .item img {
  width: 94%;
}

.resources .item .title {
  font-size: 1.2rem;
}

.socialHeader {
  width: 100%;
  padding: 2rem;
  text-align: center;
}

.socialHeader h2 {
  font-size: 3rem;
}

.socialHeader > div {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.socialHeader > div .divider {
  width: 4rem;
  margin: 0;
  margin-right: 1rem;
}

.socialHeader > div .instagram {
  width: 2rem;
  height: 2rem;
}

.socialHeader > div .facebook {
  width: 1.5rem;
  height: 1.5rem;
  margin-right: 0.5rem;
}

.socialHeader > div p {
  font-size: 1.5rem;
  margin-left: 1rem;
}

.social {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-wrap: wrap;
  padding: 0 5%;
}

.social .item {
  width: 21%;
  text-align: center;
  margin: 0 2%;
  cursor: pointer;
  min-width: 10rem;
  margin-bottom: 2rem;
  display: block;
}

.social .item img {
  width: 100%;
}

.social .item p {
  font-size: 1.2rem;
  margin-top: 0.5rem;
}

@media only screen and (max-width: 600px) {
  .modal {
    width: 100%;
    max-width: unset;
  }

  .socialHeader h2 {
    font-size: 2rem;
  }

  .resources .item {
    width: 70%;
  }

  .social .item {
    width: 80%;
  }
}