.wrap {
  width: 100%;
  background-position: top center;
  background-size: contain;
  background-repeat: no-repeat;
  background-image: url(../../assets/home-bg-video-poster.jpg);
  padding: 2rem 0;
}

.modal {
  width: 900%;
  max-width: 60rem;
}

.modal p {
  font-family: Roboto;
  margin-bottom: 0.5rem;
}

.modal > div > div {
  width: 100%;
  padding: 2rem 5%;
}

@media only screen and (max-width: 600px) {
  .modal {
    width: 100%;
    max-width: unset;
  }
}