.header {
  width: 100%;
  height: 6rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 2rem;
  position: relative;
  box-shadow: var(--box-shadow);
  background-color: white;
  z-index: 999;
}

.menuIcon {
  cursor: pointer;
}

.menuIcon svg {
  height: 2.5rem;
  transform: rotate(180deg);
}

.logo {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -75%);
  display: block;
}

.logo > img {
  height: 3.1rem;
}

.logo > div {
  width: 150%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translate(-53%, 100%);
}

.logo > div img {
  width: 1.8rem;
  margin-right: 0.6rem;
}

.button {
  font-size: 1.3rem;
}

@media only screen and (max-width: 600px) {
  .button {
    display: none;
  }

  .logo {
    right: 50%;
    left: unset;
    transform: translate(60%, -70%) scale(0.7);
  }

  .header {
    height: 4.5rem;
    padding: 0 0 0 2rem;
  }
}