.footer {
  width: 100%;
  height: 10rem;
  padding: 1rem;
}

.footer > div {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 2rem;
}

.join {
  font-size: 2rem;
  margin-right: 3rem;
}

.input {
  width: 25rem;
}

.facebookIcon {
  height: 2.3rem;
  margin-left: 2.5rem;
  cursor: pointer;
}

.instagramIcon {
  height: 3rem;
  margin-left: 1rem;
  cursor: pointer;
}

.instaUser {
  font-size: 1.5rem;
  margin-left: 1rem;
  cursor: pointer;
}

.footer > div a {
  margin-left: 1rem;
}

.footer > div a:hover {
  text-decoration: underline;
}

.error {
  margin: 0.5rem 0 0 1.5rem;
  font-family: Roboto;
  font-size: 0.9rem;
  color: red;
}

.success {
  margin: 0.5rem 0 0 1.5rem;
  font-family: Roboto;
  font-size: 0.9rem;
  color: rgb(100, 235, 100);
}

@media only screen and (max-width: 600px) {
  .footer > div {
    flex-wrap: wrap;
  }

  .join {
    margin-right: 0;
    margin-bottom: 0.5rem;
  }

  .facebookIcon {
    margin-left: -1.5rem;
    margin-top: 1rem;
  }

  .instagramIcon {
    margin-top: 1rem;
    margin-left: 0.3rem;
  }
  
  .instaUser {
    margin-top: 1rem;
  }

  .footer > div:last-child a {
    display: block;
    width: 100%;
    text-align: center;
    margin-top: 0.5rem;
    margin-left: 0;
  }

  .footer > div:last-child a:last-child {
    margin-bottom: 2rem;
  }

  .input {
    width: 100%;
  }
}