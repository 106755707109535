.wrap {
  width: 100%;
  background-position: top center;
  background-size: contain;
  background-repeat: no-repeat;
  background-image: url(../../assets/home-bg-video-poster.jpg);
  padding: 2rem 0;
}

.modal {
  width: 90%;
  max-width: 60rem;
}

.platformWrap {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 2rem;
}

.platformWrap > div {
  width: 40%;
  margin-top: 2rem;
}

.buttons {
  padding: 0 5%;
}

.button {
  width: 100%;
  margin-bottom: 0.5rem;
}

.button > div {
  min-height: 4rem;
}

.button img {
  width: 9rem;
  margin-top: 0.3rem;
}

.platformWrap h2 {
  font-size: 2rem;
}

.platformImage {
  width: 100%;
  margin-top: 1rem;
}

.divider {
  width: 90%;
  margin-top: 2rem;
}

@media only screen and (max-width: 600px) {
  .modal {
    width: 100%;
    max-width: unset;
  }

  .platformWrap {
    flex-wrap: wrap;
  }

  .platformWrap > div {
    width: 90%;
  }

  .platformWrap h2 {
    font-size: 1.5rem;
  }

  .buttons {
    padding: 0 10%;
  }

  .webPlatformWrap {
    display: none;
  }
}