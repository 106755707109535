.wrap {
  width: 100%;
  background-position: top center;
  background-size: contain;
  background-repeat: no-repeat;
  background-image: url(../../assets/home-bg-video-poster.jpg);
  padding: 2rem 0;
}

.modal {
  width: 900%;
  max-width: 60rem;
}

.modal p {
  margin-bottom: 0.5rem;
}

.content {
  width: 100%;
  margin-top: 1rem;
  min-height: 70vh;
}

.search {
  width: 90%;
  max-width: 20rem;
}

.question {
  width: 90%;
  margin: 0 auto;
  border-radius: 2rem;
  box-shadow: var(--box-shadow);
  padding: 1rem;
  margin-bottom: 1rem;
}

.question .header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 0;
  transition: margin-bottom 0.3s;
  cursor: pointer;
}

.question.active .header {
  margin-bottom: 1rem;
}

.question .header h2 {
  font-size: 1.3rem;
}

.question .header .chevronDown {
  width: 2rem;
  height: 2rem;
  transition: transform 0.3s;
  flex-shrink: 0;
}

.question.active .header .chevronDown {
  transform: rotate(180deg);
}

.question .header .chevronDown path {
  stroke: #FF9D00;
}

.question .answer {
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.3s;
}

.question.active .answer {
  max-height: 30rem
}

.question .answer a {
  text-decoration: underline;
}

@media only screen and (max-width: 600px) {
  .modal {
    width: 100%;
    max-width: unset;
  }
}