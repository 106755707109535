.home {
  width: 100%;
  height: calc(100vh - 5rem);
  min-height: 35rem;
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
  background-color: #86edf4;
  overflow: visible;
  position: relative;
}

.home video {
  width: 100%;
  height: 100%;
  position: absolute;
  object-fit: cover;
  z-index: 0;
}

.premiumPackagesButton {
  position: absolute;
  top: 1rem;
  right: 2rem;
}

.content {
  width: 90%;
  max-width: 70rem;
  background-color: rgb(255, 255, 255, 0.7);
  border-radius: 2rem;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  backdrop-filter: blur(0.4rem);
}

.content .top {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1rem;
}

.content .top > img {
  width: 37rem;
  margin-top: 2rem;
  margin-left: 4rem;
}

.content .bottom {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 2rem;
}

.content .birdWhite {
  position: absolute;
  width: 35rem;
  bottom: 0;
  left: 0;
  transform: translateY(85%);
  z-index: 999;
}

.content .birdGrey {
  display: none;
}

.promoContainer .birdGrey {
  position: absolute;
  width: 20rem;
  bottom: 0;
  left: 47%;
  transform: translateY(46%);
  z-index: 999;
}

.mindifulIcon {
  width: 9rem;
  margin-right: 1.8rem;
  margin-left: 1.8rem;
}

.star {
  width: 11rem;
  position: absolute;
  top: 0;
  left: 0;
  transform: translate(-40%, -40%);
}

.text {
  flex-shrink: 0;
  margin-right: 3rem;
}

.text h1 {
  font-size: 2.5rem;
  margin-bottom: 0.25rem;
}

.text p {
  font-size: 1.3rem;
}

.text .freeDownload {
  font-size: 2.2rem;
}

.webAppLink {
  text-decoration: underline;
  margin-right: 4rem;
  font-size: 1.1rem;
  line-height: 1.5rem;
}

.content > .buttons {
  display: none;
}

.buttons {
  width: 22rem;
}

.button {
  width: 90%;
  margin-bottom: 2rem;
}

.button img {
  height: 3rem;
  margin-top: 0.3rem;
}

.promoContainer {
  width: 100%;
  margin: 4rem 0;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  flex-wrap: wrap;
  position: relative;
  overflow: visible;
}

.video {
  display: flex;
  justify-content: center;
  align-items: center;
  background-image: url(../../assets/video-frame.jpg);
  background-size: contain;
  background-position: center center;
  background-repeat: no-repeat;
}

.video video {
  width: 30rem;
  max-width: 90%;
  margin: 4rem 9rem;
  border-radius: 1rem;
}

.videoContainer {
  text-align: center;
  z-index: -1;
}

.videoContainer h2 {
  font-size: 2rem;
}

.patentedBadge {
  width: 25rem;
}

.feedbackWrap {
  width: 100%;
  position: relative;
}

.feedbackContainer {
  width: 100%;
  padding: 4rem;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  overflow: scroll;
  background-image: url(../../assets/feedback-banner.jpg);
  background-size: cover;
  margin-bottom: 3rem;
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.feedbackContainer::-webkit-scrollbar { 
  display: none;
}

.feedback {
  background-color: white;
  min-width: 25rem;
  margin: 0 1rem !important;
  min-height: 17rem;
}

.arrow {
  border-radius: 50%;
  background: transparent linear-gradient(158deg, #FF7600 0%, #FFCE00 100%) 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #00000029;
  padding: 0.4rem;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}

.arrow > div {
  width: 3rem;
  height: 3rem;
  background-color: white;
  border-radius: 50%;
  padding: 0.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.arrow > div img {
  height: 100%;
}

.leftArrow {
  margin-right: 0.15rem;
}

.rightArrow {
  transform: rotate(180deg);
  margin-left: 0.15rem;
}

.mobileOnly {
  display: none;
}

.mobileFeedbackWrap {
  margin-bottom: 2rem;
}

.mobileFeedbackWrap h2 {
  font-size: 2rem;
  text-align: center;
}

.mobileFeedback {
  width: 95%;
  box-shadow: none !important;
  margin: 0 auto;
  margin-top: 1rem;
}

@media only screen and (max-width: 600px) {
  .premiumPackagesButton {
    display: none;
  }

  .home {
    height: unset;
    min-height: unset;
    max-width: 100vw;
    overflow-y: hidden;
  }

  .home video {
    display: none;
  }

  .content {
    max-width: 100vw;
    height: auto;
    background-color: unset;
    border-radius: unset;
    position: relative;
    top: unset;
    left: unset;
    transform: unset;
    backdrop-filter: none;
    margin: 0 auto;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    margin-bottom: -5rem;
  }

  .content .birdWhite {
    position: relative;
    top: unset;
    left: unset;
    transform: translateX(-25%);
    width: 150%;
  }

  .content .birdGrey {
    display: block;
    width: 80%;
    transform: translate(25%, -60%);
    z-index: 99999;
  }

  .content .top > img {
    width: 90%;
    margin-top: unset;
    margin-left: 0;
    margin-right: 0;
    margin: 0 auto;
    padding-top: 3rem;
  }

  .content .top .buttons {
    display: none;
  }

  .content .bottom {
    width: 100%;
    display: block;
    justify-content: unset;
    align-items: unset;
    margin-bottom: 2rem;
  }

  .content > .buttons {
    display: block;
    width: 80%;
    margin-bottom: 2rem;
  }

  .content > .buttons .button {
    display: block;
    margin: 1rem auto;
    width: 100%;
  }

  .promoContainer .birdGrey {
    display: none;
  }

  .text {
    width: 100%;
    text-align: center;
  }

  .text h1 {
    font-size: 1.8rem;
  }

  .mindifulIcon {
    width: 9rem;
    display: block;
    margin-right: unset;
    margin-left: unset;
    margin: 1rem auto;
    width: 50%;
  }

  .star {
    width: 7rem;
    top: 7%;
    left: 22%;
  }

  .webAppLink {
    display: none;
  }

  .text .freeDownload {
    font-size: 2.2rem;
    margin-top: 2rem;
  }

  .text .freeDownload span {
    display: none;
  }

  .button {
    width: 80%;
    margin-bottom: 0.5rem;
  }

  .promoContainer {
    margin-top: 0;
  }

  .video {
    background-image: none;
  }

  .video video {
    margin: 2rem 0;
    padding: 0;
    width: 90%;
  }

  .videoContainer {
    order: 1;
  }

  .patentedBadge {
    order: 0;
    width: 90%;
    margin-top: 2rem;
  }

  .desktopOnly {
    display: none;
  }

  .mobileOnly {
    display: block;
    margin-top: 2rem;
    max-width: 100%;
  }

  .feedbackWrap {
    display: none;
  }
}