.wrap {
  padding: 2rem;
  margin: 0 auto;
  border-radius: 15rem;
  background: transparent linear-gradient(151deg, #FFDF76 0%, #FFDD4D 48%, #FF9D00 100%) 0% 0% no-repeat padding-box;
  box-shadow: var(--box-shadow);
}

.content {
  background-color: white;
  border-radius: 4rem;
  box-shadow: var(--box-shadow);
  padding: 0.1rem 0;
  overflow: hidden;
}

.header {
  width: 100%;
  text-align: center;
  padding: 1rem 0;
}

.header h1 {
  font-size: 3rem;
}

.divider {
  width: 5rem;
  margin-top: 0.5rem
}

.subtitle {
  font-size: 1.3rem;
}

@media only screen and (max-width: 600px) {
  .wrap {
    padding: 1rem;
    border-radius: 10rem;
  }

  .header h1 {
    font-size: 2rem;
  }
}