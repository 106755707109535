.wrap {
  width: 100%;
  background-position: top center;
  background-size: contain;
  background-repeat: no-repeat;
  background-image: url(../../assets/home-bg-video-poster.jpg);
  padding: 2rem 0;
}

.modal {
  width: 90%;
  max-width: 70rem;
}

.header {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1.5rem 2rem 1.5rem 4rem;
}

.titleWrap {
  width: 70%;
}

.titleWrap h1 {
  font-size: 3.4rem;
}

.titleWrap > div {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.titleWrap > div p {
  font-size: 1.2rem;
}

.titleWrap .divider {
  width: 4rem;
  margin: 0;
  margin-right: 1rem;
}

.purchaseButton {
  width: 20rem;
}

.purchaseButton > div {
  background-color: #87DB00;
  padding-top: 1rem;
  padding-bottom: 1rem;
}

.purchaseButton > div > p {
  color: white;
  font-size: 1.8rem;
}

.planOptionTitle {
  width: 100%;
  margin: 0 auto;
  margin-bottom: 1rem;
  border-radius: 5rem;
  padding: 0.6rem;
  background: transparent linear-gradient(102deg, #FF7600 0%, #FFCE00 100%) 0% 0% no-repeat padding-box;
}

.planOptionTitle > div {
  border-radius: 5rem;
  background-color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  min-height: 3.8rem;
  padding: 0.4rem 0;
}

.planOptionTitle > div h2 {
  font-size: 1.5rem;
  width: 100%;
  text-align: center;
}

.plansWrap {
  width: 90%;
  display: flex;
  justify-content: space-evenly;
  align-items: flex-start;
  padding: 1rem;
  margin: 0 auto;
  margin-bottom: 1rem;
}

.plansWrap > div {
  width: 40%;
}

.plansWrap ul {
  margin: 0;
  padding: 0;
}

.plansWrap ul li {
  list-style: none;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 0.5rem;
}

.plansWrap .checkmark {
  width: 2rem;
  height: 2rem;
  fill: #87DB00;
  margin-right: 1rem;
  flex-shrink: 0;
}

.plansWrap .xIcon {
  width: 1.5rem;
  height: 1.5rem;
  fill: #FF0000;
  margin-right: 1.5rem;
  flex-shrink: 0;
}

.price {
  width: 100%;
  text-align: center;
}

.price p:first-child {
  font-size: 4.5rem;
}

.divider {
  width: 80%;
}

.info {
  width: 90%;
  margin: 0 auto;
  padding: 2rem;
  text-align: center;
}

.info p {
  margin-bottom: 0.5em;
}

@media only screen and (max-width: 1100px) {
  .modal {
    width: 100%;
    max-width: unset;
  }
}

@media only screen and (max-width: 980px) {
  .plansWrap {
    flex-wrap: wrap;
  }

  .plansWrap > div {
    width: 100%;
    margin-bottom: 2rem;
  }

  .header {
    flex-wrap: wrap;
    padding: 1rem 2rem;
    text-align: center;
  }

  .titleWrap {
    width: 100%;
  }

  .titleWrap h1 {
    font-size: 2rem;
  }

  .titleWrap .divider {
    display: none;
  }

  .titleWrap > div p {
    font-size: 1rem;
  }

  .purchaseButton {
    display: none;
  }

  .info {
    padding: 2rem 0;
  }
}