.wrap {
  width: 100%;
  background-position: top center;
  background-size: contain;
  background-repeat: no-repeat;
  background-image: url(../../assets/home-bg-video-poster.jpg);
  padding: 2rem 0;
}

.modal {
  width: 900%;
  max-width: 60rem;
}

.header {
  width: 100%;
  text-align: center;
}

.header h2 {
  font-size: 1rem;
  margin-top: 1rem;
  margin-bottom: 2rem;
}

.header img {
  width: 60%;
}

.shadowContainer {
  width: 80%;
  box-shadow: 0px -5px 20px #00000029;
  padding: 2rem;
  border-radius: 3rem;
  margin: 3rem auto;
  text-align: center;
}

.shadowContainer .divider {
  width: 5rem;
  margin: 1rem auto 1.2rem auto;
}

.shadowContainer h3 {
  font-size: 2rem;
}

.shadowContainer p {
  margin-top: 1rem;
}

.stepByStep {
  width: 75%;
  margin: 0 auto;
  display: grid;
  grid-template-columns: 30% 10% 20% 10% 30%;
  align-items: center;
}

.stepByStep .chevron {
  transform: rotate(-90deg);
  width: 4rem;
  height: 4rem;
  justify-self: center;
}

.stepByStep .chevron path {
  stroke: #FF9D00;
}

.stepByStep img {
  width: 100%;
}

.kidsHandsImage {
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
  background-image: url(../../assets/about-kids.jpg);
  width: 100%;
  min-height: 10rem;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 3rem 0;
}

.kidsHandsImage h3 {
  font-size: 2rem;
  color: white;
  text-shadow: 0px 3px 10px #00000045;
}

.missionTitle {
  width: 100%;
  text-align: center;
  margin-top: 3rem;
}

.missionTitle h3 {
  font-size: 2rem;
}

.missionTitle .divider {
  width: 5rem;
  margin: 1.5rem auto;
}

.mission {
  margin-top: 2rem;
  padding: 1rem;
  border-radius: 2rem;
  margin-bottom: 2rem;
}

.mission p {
  font-size: 1.35rem;
  margin: 0.5rem 0;
}

.missionEnd {
  width: 75%;
  margin: 0 auto;
  text-align: center;
}

.community h3 {
  font-size: 1.5rem;
  text-align: left;
}

.innerDivider {
  width: 70%;
  margin: 0.5rem auto;
  border-bottom: thin solid #FF9D00;
}

.communityList {
  width: 100%;
  margin: 0 auto;
  text-align: left;
  padding-left: 2rem;
  margin-top: 2rem;
}

.item {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin: 1rem 0;
}

.item p {
  margin: 0;
}

.point {
  padding: 0.5rem;
  background: transparent linear-gradient(151deg, #FFDF76 0%, #FFDD4D 48%, #FF9D00 100%) 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #00000029;
  border-radius: 50%;
  margin-right: 1rem;
}

.point > div {
  background-color: white;
  width: 1rem;
  height: 1rem;
  border-radius: 50%;
}

.kidParentImage {
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
  background-image: url(../../assets/about-laptop-tablet.jpg);
  width: 100%;
  min-height: 10rem;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 3rem 0;
  text-align: center;
}

.kidParentImage p {
  font-size: 1.5rem;
  color: white;
  text-shadow: 0px 3px 10px #00000045;
}

.moreInfo {
  width: 80%;
  margin: 0 auto;
  text-align: center;
}

.moreInfo p {
  margin: 1rem 0;
}

.contactButton {
  width: 18rem;
  margin: 3rem auto;
}

@media only screen and (max-width: 600px) {
  .modal {
    width: 100%;
    max-width: unset;
  }

  .header img {
    width: 90%;
  }

  .shadowContainer {
    width: 90%;
    padding: 1rem;
  }

  .shadowContainer h3 {
    font-size: 1.8rem;
  }

  .stepByStep {
    display: block;
    text-align: center;
  }

  .stepByStep img {
    width: 10rem;
    display: block;
    margin: 0 auto;
  }

  .stepByStep .chevron {
    transform: none;
  }

  .kidsHandsImage h3 {
    font-size: 1.5rem;
    width: 100%;
    text-align: center;
  }
  
  .missionTitle h3 {
    font-size: 1.5rem;
  }

  .mission p {
    font-size: 1.2rem;
  }

  .community h3 {
    width: 100%;
    text-align: center;
    font-size: 1.4rem;
  }

  .communityList {
    padding-left: 0;
  }

  .kidParentImage p {
    font-size: 1.3rem;
  }
}