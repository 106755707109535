.search {
  width: 100%;
  margin: 0 auto;
  background: transparent linear-gradient(106deg, #FFDF76 0%, #FFDD4D 48%, #FF9D00 100%) 0% 0% no-repeat padding-box;
  box-shadow: var(--box-shadow);
  border-radius: 5rem;
  padding: 0.6rem;
}

.search > div {
  width: 100%;
  background-color: white;
  border-radius: 5rem;
  box-shadow: var(--box-shadow);
  display: flex;
  justify-content: flex-start;
  align-items: center;
  overflow: hidden;
}

.icon {
  width: 2rem;
  height: 2rem;
  margin: 0 0.5rem 0 1rem;
}

.input {
  border: none;
  outline: none;
  width: calc(100% - 3.5rem);
}

.input::placeholder {
  color: #FFDD4D;
}