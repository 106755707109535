.wrap {
  padding: 0.5rem 1rem;
  border-radius: 3rem;
  box-shadow: var(--box-shadow);
  display: flex;
}

.wrap input {
  width: 100%;
  margin: 0 1rem 0 0.5rem;
  border: none;
  border-bottom: medium solid #FFDD4D;
  outline: none;
}

.wrap input::placeholder {
  color: #FFDD4D;
}