.bg {
  background: transparent linear-gradient(126deg, #FFDF76 0%, #FFDD4D 48%, #FF9D00 100%);
  box-shadow: var(--box-shadow);
  border-radius: 2.5rem;
  padding: 0.5rem;
  box-sizing: border-box;
  cursor: pointer;
  font-size: 1rem;
  display: block;
}

.wrap {
  background-color: white;
  box-shadow: var(--box-shadow);
  border-radius: 2.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.5em 1.2em;
  text-align: center;
  flex-wrap: wrap;
  box-sizing: border-box;
}

.disabled {
  opacity: 0.8;
}

.disabled .wrap {
  opacity: 0.3;
}