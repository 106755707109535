.wrap {
  width: 100%;
  background-position: top center;
  background-size: contain;
  background-repeat: no-repeat;
  background-image: url(../../assets/home-bg-video-poster.jpg);
  padding: 2rem 0;
}

.modal {
  width: 90%;
  max-width: 67rem;
}

.header {
  width: 100%;
  text-align: center;
  padding: 1rem 0;
}

.header h1 {
  font-size: 3rem;
}

.fiveStars {
  margin: 0 auto;
}

.divider {
  width: 5rem;
  margin-top: 0.25rem;
}

.featured {
  width: 90%;
  margin: 1rem auto;
  background: #ffad1570 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #00000029;
  border-radius: 3rem;
  padding: 0.25rem 1.5rem 1rem 1.5rem;
  position: relative;
}

.featured h3 {
  font-size: 1.5rem;
  margin: 1rem 0 1.5rem 0;
}

.featured p {
  font-family: Roboto;
}

.starsAuthor {
  width: 7.5rem;
  text-align: center;
  flex-shrink: 0;
  margin-left: 0.5rem;
}

.starsWrap {
  background: #FFAF15 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #00000029;
  border-radius: 2rem;
  padding: 0.25rem 0.6rem;
}

.starsAuthor p {
  font-family: Roboto;
  margin-top: 0.1rem;
  font-size: 0.9rem;
}

.featuredHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.reviews {
  padding: 1rem;
  width: 95%;
  margin: 0 auto;
  display: grid;
  grid-template-columns: 50% 50%;
}

.review {
  width: 95%;
  margin: 0 auto;
  margin-bottom: 2rem;
  box-shadow: 0px -5px 20px #00000029;
  border-radius: 2rem;
  padding: 1rem;
}

.review > p {
  font-family: Roboto;
}

.review > div {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1rem;
}

.review > div h3 {
  font-size: 1.3rem;
}

@media only screen and (max-width: 600px) {
  .modal {
    width: 100%;
    max-width: unset;
  }

  .featured {
    padding-bottom: 1rem;
  }

  .reviews {
    display: block;
  }

  .review {
    width: 100%;
    margin-bottom: 1rem !important;
  }

  .featured h3 {
    font-size: 1.3rem;
  }
}