.wrap {
  width: 100%;
  background-position: top center;
  background-size: contain;
  background-repeat: no-repeat;
  background-image: url(../../assets/home-bg-video-poster.jpg);
  padding: 2rem 0;
}

.modal {
  width: 90%;
  max-width: 60rem;
}

.header {
  width: 100%;
  min-height: 18rem;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  background-position: top center;
  background-repeat: no-repeat;
  background-size: cover;
  background-image: url(../../assets/contact-bg.jpg);
  margin-top: -0.1rem;
}

.header > div {
  margin-bottom: 1rem;
}

.header h1 {
  font-size: 3.5rem;
}

.header .divider {
  width: 6rem;
}

.text {
  width: 100%;
  text-align: center;
  padding: 2rem;
  padding-bottom: 0;
}

.text p {
  margin: 2rem 0;
}

.button {
  width: 25rem;
  margin: 2rem auto;
}

.button p {
  font-size: 1.4rem;
}

.social {
  width: 90%;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  align-items: center;
}

.social p {
  font-size: 1.4rem;
}

.facebook {
  width: 2rem;
  height: 2rem;
  margin-left: 1rem;
}

.instagram {
  width: 3rem;
  height: 3rem;
  margin-left: 1rem;
}

.socialTags {
  width: 80%;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 1rem;
  margin-bottom: 3rem;
}

.socialTags p {
  color: #FFAF15;
  margin: 0 2rem;
}

@media only screen and (max-width: 600px) {
  .modal {
    width: 100%;
    max-width: unset;
  }

  .button {
    width: 90%;
  }

  .socialTags p {
    margin: 0 0.5rem;
  }

  .social {
    flex-wrap: wrap;
  }

  .social p {
    width: 100%;
    text-align: center;
    margin-bottom: 1rem;
  }

  .facebook {
    margin-left: 0;
  }
}